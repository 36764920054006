import React from 'react';
import Services from '../Services/DisplayServices';
import Products from '../Products/ProductDisplay';
import About from '../About/AboutUs';
import Contact from '../Contact/ContactUs';
import Testimonial from '../Testimonial/WhatClientsSay';

export default function Body(){
    return (
        <>
            <Services/>
            <Products/>
            <About/>
            <Testimonial/>
            <Contact/>
        </>
    )
}
