import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Slider({testimony}){
    return (
        <div className="row d-flex justify-content-center my-2 mx-2">
            <div className="col-md-3 text-center">
                <img className="rounded-circle shadow-1-strong my-3 w-50" src={testimony.profile} alt={testimony.name} />
            </div>
            <div className="col-md-7">
                <div className="text-start">
                    <div className="text-center mb-2">
                        <span className="h5 text-dark text-uppercase">{testimony.name}</span> 
                        <p className="text-muted">
                            <span className="ps-2">{testimony.jobTitle}</span>
                            <span className="ps-2">{testimony.jobPlace}</span>
                        </p>
                    </div>
                    <p className="text-center">
                        <FontAwesomeIcon icon="fas fa-quote-left" className="pe-2" />{testimony.testimony}
                    </p>
                </div>
            </div>
        </div>
    )
}