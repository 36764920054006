import './App.css';
import Header from './Components/Header/Header';
import Body from './Components/Body/Body';

import { library } from '@fortawesome/fontawesome-svg-core'

import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

function App() {
  return (
    <>
    <div className="App" style={{backgroundImage: "url(/background/bg_02.jpeg)" }}>
      <Header/>
      <Body/>
    </div>
    </>
  );
}

export default App;
library.add(fab, fas, far);
