import React from 'react';
import './Header.css';
import Menus from './NavigationMenu';
import Slide from '../Carousel/SlidingSection';

export default function Header(){
    return (
        <div>
            
            <Menus/>
            <Slide/>
        </div>
    )
}
