import React from 'react';
import { Card } from 'react-bootstrap';
import './Products.css';
import {  } from '@fortawesome/free-solid-svg-icons'

function DisplayFormat({product}){
    return(
        <Card className='col-md-10 pb-sm-4 border-rounded-0 border border-0'>
                <a href={product.link} className='text-decoration-none' aria-label={product.name} rel='noopener noreferrer' target='_blank'>
                    <Card.Img variant="top" src={product.image} alt={product.name} />
                </a>
            <Card.Body>
                <Card.Title className='text-start' ><span className="h3" style={{color: '#031655'}}>{product.title}</span></Card.Title>
                <Card.Text className='text-start'>{product.description}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default DisplayFormat;