import React from 'react';
import Item from '../Services/HelperScript'


export default function AboutUs(){
    return (
        <div className='mx-3' style={{marginTop: '2em'}} id="about">
            <Item heading="About us" />
            <div className='row mb-3' style={{color: '#050505'}}>
            <p className='text-justify my-2 py-2'>
                True Computing Technology offer diverse IT solutions ranging from automation of business processes as information system and provide reliable seamless information technology solutions and consultancy to clients.
            </p>
                <div className='col-md-6 '>
                    <div className='h3 text-uppercase text-center mt-2'>
                        Vision
                    </div>
                    <p className='d-block text-start'>
                        To provide ubiquitous, secure and seamless access to information technology in East Africa.
                    </p>
                </div>
                <div className='col-md-6'>
                    <div className='h3 text-uppercase text-center mt-2'>
                    Mission
                    </div>
                    <p className='d-block text-start'>
                    True Computing Technology is a software and information technology company that applies the latest user-friendly technologies to deliver usable information systems and provide information technology solutions and knowledge.                    </p>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-md-12'>
                    <div className='h3 text-uppercase text-center py-3'>
                        Values
                    </div>
                    <div className='my-2 pb-2'>
                        <img src='/value/values.png' className='img img-fluid img-responsive' alt='Core Values'/>
                    </div>
                </div>
            </div>
        </div>
    )
}