import React from "react";
import Item from '../Services/HelperScript';
import TestimonyObject from './ObjectDescription';

function WhatClientsSay(){
    return (
        <div className="mx-3" style={{marginTop: '2em'}} id="testimonial">
            <Item heading="Testimonial" />
            <div className="row">
                <div className="col-md-12">
                    <TestimonyObject/>
                </div>
            </div>
        </div>
    )

}

export default WhatClientsSay;