import React from "react";
import SlideItem from "./Slider";
import Carousel from 'react-bootstrap/Carousel';

export default function ObjectDescription(){
    
    const testimony_01 = {
        profile: './testimonies/user.svg', 
        name: 'Doreen Kisembo',
        jobTitle: 'Researcher',
        jobPlace: 'Makerere University',
        testimony: 'True Computing Technology services are exceptional. Their service delivery has a touch of excellency and  professionalism while their timeliness is unmatched. They have supported me in installing programs on new machines and they have been on point at all times. Regular maintenance services offered by them has seen my machines perform at their very best!', 
    }

    const testimony_02 = {
        profile: './testimonies/user.svg',
        name: 'Godfrey L. Kimbugwe',
        jobTitle: 'C.E.O',
        jobPlace: 'Pixel Planet',
        testimony: 'TCT is excellent! It delivered accounting package solution software and configured it to meet Pixel Planet\'s accounting needs. They also help to offer after sale services of user training and co-respondence.'
    }

    // const testimony_03 = {
    //     profile: './testimonies/user.svg',
    //     name: 'Susan Ritah Kagimu',
    //     jobTitle: ' Manager, Children Development Programs',
    //     jobPlace: 'Dail Social Welfare Foundation',
    //     testimony: 'True Computing Technology services are perfect. They supported our internal IT infrastructure to perform faster and securely, and provided data recovery.'
    // }
    return (
        <Carousel>
            <Carousel.Item interval={5000}>
                <SlideItem testimony={testimony_01} />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
                <SlideItem testimony={testimony_02} />
            </Carousel.Item> 
            {/* <Carousel.Item interval={5000}>
                <SlideItem testimony={testimony_03}/>
            </Carousel.Item>     */}
        </Carousel>
    )
}