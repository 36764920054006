import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './SlidingSection.css';

export default function SlidingSection(){
    return(
    <Carousel>
        <Carousel.Item interval={5000}>
            <img className="d-block  w-100" src="./slide/img_bg_2.png" alt="First slide" />
            <Carousel.Caption>
            <h3 className='text-dark'>Creative Usable Quality</h3>
            <a className='btn btn-dark' href="#contact">Get started</a>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
            <img className="d-block w-100" src="./slide/img_bg_4.jpg" alt="Training" />
            <Carousel.Caption>
            <h3 className='text-white'>Digital Security Training with Journalists.</h3>
            <a className='btn btn-dark' href='#contact'>Get started</a>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
            <img className="d-block w-100" src="./slide/img_bg_3.png" alt="Third slide" />
            <Carousel.Caption>
            <h3 className='text-dark'>Memorable Customer Experience</h3>
            <a className='btn btn-dark' href='#contact'>Get started</a>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
    )
}